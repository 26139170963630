import * as geoLocationActions from '@src/redux/actions/geolocation';
import { fetchCritAndZip } from '@src/utils/geolocation';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { geolocated } from 'react-geolocated';
import Autocomplete from 'react-google-autocomplete';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';

import { getAddressData, getGeoLocationByLatLong } from './helpers';
import { PlacesModal } from './PlacesModal';

let timeout;

const autocompletePlaces = (getDataFromPlace, options, formattedAddress, onKeyDown, solo = true) => (
	<Autocomplete
		apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}
		onPlaceSelected={getDataFromPlace}
		options={options}
		defaultValue={formattedAddress}
		className={solo ? 'search-location-input' : 'search-location-input search-location-input--modal'}
		placeholder="Please enter City, Zip or Address"
		{...(onKeyDown && { onKeyDown: () => onKeyDown() })}
	/>
);

const PlacesSearch = props => {
	const router = useRouter();
	const { isModalOpen, isFull = true } = props;
	const options = {
		types: props.addressSearch ? ['address'] : ['(regions)'],
		componentRestrictions: { country: ['us', 'ca'] },
	};

	const [dataSet, setDataSet] = useState(false);
	const [data, setData] = useState(undefined);
	const [isUserTyping, setIsUserTyping] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [locationPermissionApprovedLoading, setLocationPermissionApprovedLoading] = useState(false);
	const [loadingCurrentGeolocation, setLoadingCurrentGeolocation] = useState(false);
	const [isGeolocationEnabled, setIsGeolocationEnabled] = useState(false);
	const [isGeolocationAvailable, setIsGeolocationAvailable] = useState(false);

	const getGeoLocation = () => {
		const geoidx = localStorage.getItem('_mr_geoidx');
		if (geoidx) {
			return JSON.parse(geoidx);
		}
		return null;
	};

	useEffect(() => {
		setIsOpen(isModalOpen);
	}, [isModalOpen]);

	useEffect(() => {
		if (props.address?.isLocationLoading !== loadingCurrentGeolocation) {
			props.setLocationLoading(loadingCurrentGeolocation);
		}
	}, [loadingCurrentGeolocation]);

	useEffect(() => {
		setIsGeolocationEnabled(props.isGeolocationEnabled);
		setIsGeolocationAvailable(props.isGeolocationAvailable);
	}, [props]);

	const getData = async () => {
		const geolocation = getGeoLocation();
		if (geolocation && geolocation?.latitude !== null) {
			setLoadingCurrentGeolocation(true);
			const dt = await getGeoLocationByLatLong(geolocation?.latitude, geolocation?.longitude, geolocation);
			if (props.addressSearch) {
				setData({ ...dt, formattedAddress: dt.formatted_address });
			} else {
				setData({ ...dt, formattedAddress: `${dt.city} ${dt.state}, ${dt.country}` });
			}
			setLoadingCurrentGeolocation(false);
		} else {
			setData(null);
			setLoadingCurrentGeolocation(false);
		}
	};

	useEffect(() => {
		const { crit } = router.query;

		const getCritGeo = async () => {
			const payload = await fetchCritAndZip(crit, true);
			if( payload == null){ return;}
			setData(payload);
			
			if (props.address.latitude !== payload.latitude && props.address.longitude !== payload.longitude) {
				props.setLocation(payload);
			}
		};
		if (!data) {
			if (crit) {
				getCritGeo();
			} else {
				getData();
			}
		}
	}, [data]);

	useEffect(() => {
		if (!data && dataSet === false) {
			if (props.address.latitude && props.address.longitude) {
				props.setLocation(props.address);
				setData({
					...props.address,
					formattedAddress: props.address.formatted_address,
				});
				setDataSet(true);
			}
		}
	}, [props.address, data]);

	useEffect(() => {
		// Because we have input fields inside of the page content
		const modalNotRequired = window.location.href.includes('category') || window.location.href.includes('product');
		if (!modalNotRequired) {
			if (isModalOpen !== false) {
				if (data) {
					timeout = setTimeout(() => setIsOpen(true), 5000);
					if (data) {
						clearTimeout(timeout);
					}
				}
			}
		}
	}, [data, isModalOpen]);

	useEffect(() => {
		if (isUserTyping) {
			clearTimeout(timeout);
		}
	}, [isUserTyping]);

	const getDataFromPlace = place => {
		setLoadingCurrentGeolocation(true);
		const data = getAddressData(place);
		if (data) {
			setAddressData(data ? data : null);
		}
		setTimeout(() => setLoadingCurrentGeolocation(false), 1000);
	};

	const onCloseModal = () => setIsOpen(false);

	const setAddressData = (addressData, short = false) => {
		const formattedData = {
			...addressData,
			formattedAddress: short
				? `${addressData.city}, ${addressData.country}`
				: addressData?.formatted_address || addressData?.formattedAddress,
			formatted_address: short
				? `${addressData.city}, ${addressData.country}`
				: addressData?.formatted_address || addressData?.formattedAddress,
		};

		setData(formattedData);
		props.setLocation(formattedData);
		setTimeout(() => setIsOpen(false), 2000);
	};

	const onKeyDown = () => {
		setIsUserTyping(true);
	};

	const requestLocation = () => {
		setLoadingCurrentGeolocation(true);
		if (!navigator.geolocation) {
			toast.error('Geolocation is not supported by your browser');
			this.requestLocation();
			setLoadingCurrentGeolocation(false);
		}

		if (!navigator.geolocation || !props.isGeolocationEnabled) {
			toast.error('Geolocation is not supported by your browser');
			setLoadingCurrentGeolocation(false);
		} else {
			navigator.geolocation.getCurrentPosition(
				async position => {
					const latitude = position.coords.latitude;
					const longitude = position.coords.longitude;
					const location = await getGeoLocationByLatLong(latitude, longitude, props.geolocation);
					setAddressData(location, true);
					props.setLocation(location);
					setLoadingCurrentGeolocation(false);
				},
				function (error) {
					if (error.code == 3 && !props.geolocation.latitude) {
						toast.error(
							'We were unable to retrieve your location. Please enter it manually in the location form'
						);
					}
					setLoadingCurrentGeolocation(false);
				},
				{
					timeout: 30000,
					enableHighAccuracy: true,
					maximumAge: Infinity,
				}
			);
		}
	};

	return (
		<div>
			{!props.modalOnly && (
				<div>
					<div className={`place-selection extended ${props.hasMyLocationButton && 'has-my-location'}`}>
						{loadingCurrentGeolocation && (
							<span className={`input-loading-status`}>
								<i className="fas fa-circle-notch fa-spin"></i>Loading your location...
							</span>
						)}
						{isFull ? (
							<div>
								{props.hasMyLocationButton && (
									<div>
										<button className="btn" onClick={() => requestLocation()}>
											<i className="fas fa-map-marker-alt" aria-hidden="true"></i> Use My Location
										</button>

										<div className="or-container mt-2 mb-2">
											<div className="line"></div>
											<div>
												<span>OR</span>
											</div>
											<div className="line"></div>
										</div>
									</div>
								)}

								<div className="input-pack">
									<div className="location-icon-container">
										<i className="fas fa-map-marker-alt"></i>
									</div>
									{autocompletePlaces(getDataFromPlace, options, data?.formattedAddress, onKeyDown)}
								</div>
							</div>
						) : (
							autocompletePlaces(getDataFromPlace, options, data?.formattedAddress, onKeyDown)
						)}
					</div>
					{props.coords && props.coords.latitude}
				</div>
			)}
			<PlacesModal
				isOpen={isOpen}
				onCloseModal={onCloseModal}
				locationPermissionApprovedLoading={locationPermissionApprovedLoading}
				isGeolocationEnabled={isGeolocationEnabled}
				isGeolocationAvailable={isGeolocationAvailable}
				loadingCurrentGeolocation={loadingCurrentGeolocation}
				getCurrentLocation={() => {
					setLocationPermissionApprovedLoading(true);
					requestLocation();
				}}>
				{autocompletePlaces(getDataFromPlace, options, data?.formattedAddress, null, false)}
			</PlacesModal>
		</div>
	);
};

const mapStateToProps = state => ({
	masterProvider: state.masterProvider,
	address: state.geolocation,
});

const mapDispatchToProps = dispatch => ({
	setLocation: payload => dispatch(geoLocationActions.setLocation(payload)),
	resetLocation: payload => dispatch(geoLocationActions.resetLocation(payload)),
	setLocationLoading: payload => dispatch(geoLocationActions.setLocationLoading(payload)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	geolocated({
		suppressLocationOnMount: true,
		positionOptions: {
			enableHighAccuracy: true,
		},
		watchPosition: false,
		isOptimisticGeolocationEnabled: true,
		userDecisionTimeout: 30000,
		onSuccess: position => {
			this.onSuccess(position);
		},
		positionError: error => {
			this.positionError(error);
		},
	})(PlacesSearch)
);
